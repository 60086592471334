.contato-wrapper {
    border-bottom: 7px solid #144AA0;
}

.mapa{
    line-height: 0;
}

.mapa-container{
    line-height: 0;
}


@media (max-width: 768px) {
    .mapa{
        height: 200px;
    }

}