.biografia-wrapper {
    padding: 90px 70px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.biografia-content{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
    gap: 70px;

}
.biografia-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
    gap: 70px;

}
.flex-bio{
    margin-bottom: 50px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.solicitacao{
    margin-bottom: -3px;
}
.flex-bio-reverse{
    margin-bottom: 50px !important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

}
.main-title{
    text-align: center;
    margin-bottom: 80px;
}
.main-title-2{
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
 
}
.titulos{
    font-size: 28px;
    letter-spacing: 0.05rem;
    color: #03287B;
    line-height: 1.3;
      font-family: 'Primaria2';
}
.textos{
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 70%;
}
.textos-p{
    max-width: 600px !important;
    font-size: 18px;
    color: #03287B;
}
img.img-tercio {
    max-width: 350px !important;
}
/* Biografia.css */
.invisible {
    opacity: 0;
    transition: opacity 1s ease-out;
  }
  
  .visible {
    opacity: 1;
  }
  

@media (max-width: 768px) {
    .biografia-container {
        padding-top: 10px;
        flex-direction: column;
    }
}
