/* PrevArrow.css */
.arrow {
  cursor: pointer;
  font-size: 24px; /* Escolha o tamanho desejado */
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.fa-arrow-right {
  display: block;
  color: #03287B;
  right: -40px;
}

.fa-arrow-left {
  display: block;
  color: #03287B;
  left: -40px;
}
