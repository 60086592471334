.inclusao-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .inclusao-container {
    max-width: 800px; /* Ajuste conforme necessário */
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  .insta-content {
    margin-bottom: 20px;
  }
  .insta-content h2{
    font-size: 60px;
    letter-spacing: 0.05rem;
    color: #03287B;
    line-height: 1.3;
      font-family: 'Primaria2';
  }
  
  .slide-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .slide-in-visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .inclusao-img {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Ajuste conforme necessário */
    gap: 20px;
    justify-items: center;
    margin-bottom: 20px;
  }
  
  .card {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
    max-height: 300px; /* Altura máxima para as imagens */
  }
  
  .card img:hover {
    transform: scale(1.1);
  }
  
  .instagram-img {
    width: 60px; /* Ajuste conforme necessário */
    height: auto;
    margin-bottom: 10px;
  }
  
  /* Estilos adicionais para responsividade */
  @media (max-width: 768px) {
    .inclusao-img {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 576px) {
    .inclusao-img {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
  }
  