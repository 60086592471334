.header-bg{
  width: 100%;
  height: 120px;
  background-color: #02226c;
}

.noticias-wrapper {
    padding: 90px 20px;
    margin-top: 40px;
}

.noticias-container {
    max-width: 1170px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.noticias-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.noticias-container div {
    flex: 1;
}

.noticias-content h2 {
  font-size: 60px;
  letter-spacing: 0.05rem;
  color: #03287B;
  line-height: 1.3;
    font-family: 'Primaria2';
}

.noticias-content p {
  font-size:25px;
  line-height: 1.3;
  color: #02226c;
  font-family: 'Secundaria';
}

.noticia-images {
  max-width: 100%;  
  height: 270px; 
  object-fit: cover; 
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.noticias-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .noticias-container {
    max-width: 85%;
    padding: 20px;
    border-radius: 8px;
  }
  
  .noticias-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .noticias-cards {
    grid-gap: 30px;
      grid-row-gap: 40px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      row-gap: 40px;
  }
  .noticia-image{
   border-radius: 10px !important;
   margin-bottom: 20px;
  }
  
  .noticia-card {
    background-color: #144AA0;
    border-radius: 20px;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .textos-news{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .noticia-card h3 {
    display: flex !important;
    margin-bottom: 15px !important;
    font-size: 16px;
    font-family: 'Secundaria';

  }
  .redirect{
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #FFBB40;
    max-width: fit-content;
    margin: auto !important;
    margin-bottom: 10px !important;
    color: #02226c !important;
    margin-bottom: 10px;
  }

  .redirect a{
    color: #02226c !important;
    text-decoration: none !important;
    font-size: 18px;
    font-family: 'Secundaria';
  }
  .noticia-card p {
    display: flex !important;
    margin-bottom: 10px !important;
    font-size: 14px;
    font-family: 'Secundaria';
  }
 .textos-news small{
  color: #c0c0c0;
 }
  

@media (max-width: 768px) {
    .noticias-container {
        padding-top: 10px;
        flex-direction: column;
    }
}
