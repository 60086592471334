.nossosprojetos-wrapper {
  padding: 90px 20px;
  margin-bottom: 100px !important;
  margin-top: 20px;
}

.nossosprojetos-container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  gap: 40px;
}

.nossosprojetos-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.nossosprojetos-container div {
  flex: 1;
}

.nossosprojetos-content h2 {
  font-size: 60px;
  letter-spacing: 0.05rem;
  color: #03287B;
  line-height: 1.3;
  font-family: 'Primaria2';
}


.nossosprojetos-content p {
  font-size: 18px;
  line-height: 1.3;
}

.nossosprojetos-img {

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
}
.nossosprojetos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.nossosprojetos-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nossosprojetos-content {
  text-align: center;
  margin-bottom: 20px;
}

.nossosprojetos-img {
  width: 100%;
  border-radius: 30px;
}

.slide-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .nossosprojetos-container {
    padding-top: 10px;
    flex-direction: column;
  }
}
