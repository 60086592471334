
 .lei-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 100px;
    background-color: #081b47;
    background-image: url("../../assets/imgs/fundo1.png");
    background-repeat: no-repeat;
    background-position: left center;

    
}
.quadrado{
    background-color: rgb(12, 98, 236);
    color: aliceblue;
    max-width: 500px;
    padding: 50px;
    border-radius: 15px;
    text-align: center;
    gap: 30px;
    font-family: 'Secundaria';
    font-size: 35px;
    font-weight: 700;

}
.quadrado p{
    font-size: 22px;
    line-height: 1.3;
    color:white;
    font-family: 'Secundaria';
    font-weight: 700;
}
.numeros-lei{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 40px;
} 
.shape-small-4{
    z-index: 100;
    position: absolute;
    border: 50%;
    background-color: #081b47;
    filter: blur(50px);
  }
  .shape-small-4 {
    width: 150px;
    height: 150px;
    bottom: 50rem;
    top: 60rem;
    left: 75rem;
  }

