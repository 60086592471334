.contato-wrapper {
  padding: 60px 20px;
  background-color: rgb(28, 148, 240);
}

.contato-container {
  padding: 30px;
  max-width: 1170px;
  margin: auto;
  display: flex;
  gap: 40px;
}

.contato-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contato-content h2{
    font-family: 'Primaria2';
    color: #03287B !important;
}
.endereco{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.flex-row{
    display: flex;
    align-items: center;
}
.contatos {
  display: flex;
  align-items: center;
  gap: 15px;
}
.img-localizacao{
  max-width: 30px;
}
.contato-container div {
  flex: 1;
}

.contato-content h2 {
  font-size: 50px;

}

.contato-content p {
  font-size: 22px;
  line-height: 1.3;
  color: white;
}
.flex-end{
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
}
.flex-end a{
    margin-top: 10px;
    background-color: #03287B;
    max-width: fit-content;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 18px;
}
.contato-img {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
p.endereco-nome {
  font-family: "Primaria2";
  font-size: 28px;
}
@media (max-width: 768px) {
  .contatos {
    padding-top: 10px;
    flex-direction: column-reverse;
  }
}
