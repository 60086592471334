
.App{
  font-family: sans-serif;
}
@font-face {
  font-family: 'Secundaria';
  src: url('../src/assets/fonts/Outfit-Regular.ttf') format('truetype');
 
}

@font-face {
  font-family: 'Primaria';
  src: url('../src/assets/fonts/ZuumeRegular.ttf') format('truetype');
  color: #03287B;
}
@font-face {
  font-family: 'Primaria2';
  src: url('../src/assets/fonts/ZuumeBoldItalic.ttf') format('truetype');
}
body {
  font-family: 'Secundaria', Arial, sans-serif;
}


.whats-btn a {
    bottom: 2rem;
    color: #fff;
    font-size: 60px;
    opacity: 1;
    padding: 10px;
    position: fixed;
    right: 2rem;
    transition: .4s;
    z-index: 1000;
}
.zapzap{
  width: 200px !important;
}
.entrecttverde{
  background-color: #018725;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 19px;
  font-family: 'Secundaria';

}
.acessibilidade-btn{
  border-radius: 100px;
  bottom: 2rem;
  color: #fff;
  font-size: 60px;
  opacity: 1;
  padding: 10px;
  position: fixed;
  right: 1.5rem;
  transition: .4s;
  z-index: 0;
  z-index: 60;
  margin-bottom: 100px;
  margin-left: 10px;
  max-width: 100px;

}

h1.acessibilidade {
  font-size:70px;
}

h2.acessibilidade {
  font-size: 60px;
}
h3.acessibilidade {
  font-size: 55px;
}
h4.acessibilidade {
  font-size: 30px;
}
h5.acessibilidade {
  font-size: 26px;
}
p.acessibilidade {
  font-size: 29px;
}
a.acessibilidade {
  font-size: 24px;
}
p{
  line-height: 1.4;
}
.link-with-image {
  display: flex;
  align-items: center;
}

.left-image {
  margin-right: 10px; /* Espaço entre a imagem e o link */
  width: 24px; /* Ajuste o tamanho conforme necessário */
  height: 24px; /* Ajuste o tamanho conforme necessário */
}


@media (max-width: 768px) {
  .inclusao-img{
    flex-direction: column;
    gap: 20px !important;
  }

  .inclusao-wrapper{
    padding: 100px 20px;
  }

  .flex-bio,
  .flex-bio-reverse{
    flex-direction: column;
  }
  .textos{
    max-width: 100%;
    padding: 20px 0;
  }

  .biografia-wrapper{
    
    padding: 100px 20px;
    padding-top: 0px;
  }

  img.img-tercio {
    max-width: 100% !important;
  }

  .main-title{
    margin-bottom: 40px;
  }

  .noticias-container{
    max-width: 100% !important;
    padding: 20px 0 !important;

  }

  .noticias-cards {
    flex-direction: column;

  }

  .nossosprojetos-wrapper{
    margin-top: 0 !important;
  }

  .tercioimg{
    max-width: 500px;
  }
  .hero-title{
    margin-top: 100px;
    max-width: 500px !important;
  }

  .lei-wrapper{
    padding: 30px;
    flex-direction: column;
  }
  .numleis-content{
    padding: 10px;
    display: flex;
    gap: 40px;
  }

}