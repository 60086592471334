.footer-container {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  max-width: 1350px;
  padding: 40px 55px;
  margin: auto;
}

.footer-logo{
  color: #fff;
}

.footer-background {
  background-color: #144AA0;
  background-repeat: repeat !important;
  z-index: 20;
  position: relative;
}
.logo-footer{
  max-width: 200px !important;
}
.footer-container .footer-container-division {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: auto;
}
.luana{
  background-color: #23A6F0 ;
  width: 100%;
  text-align: center !important;
  padding: 15px;
  display: flex !important;
  gap: 10px;
  align-items: center !important;
  justify-content: center !important;
  color: #000000;
  font-weight: bold;
}
.luana a{
  color: #2d00df;
  text-decoration: underline !important;
}
.footer-container h5 {
  color: #ffffff;
  font-family: 'futura-pt-bold', sans-serif;
  font-size: 12px;
  margin-bottom: 25px;
  font-weight: 400;
}

.footer-container h4 {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
}
.tt{
  display: flex;
  line-height: 1;
}
/* .tt img{
  margin-top: 2px;
  max-width: 15px !important;
} */
.ttimg{

  max-width: 15px !important;
}
.tkimg{
  max-width: 15px !important;
}
.footer-container a,
.footer-menu button {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  max-width: fit-content;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  text-transform: none;
  background-color: transparent;
}

.footer-container a:hover,
.footer-menu button:hover {
  color: #ffffff;
  border-bottom: 1px solid #fff;
}

.footer-menu div {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.footer-container p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 1.6;
}

.footer-endereco span {
  display: block;
  margin-bottom: 5px;
  color: #ffffffcc;
}

.footer-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-wrap div {
  flex: 1;
}

.newsletter-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-content div {
  min-width: 350px;

}
.socials a{
  margin-bottom: -15px;
}
.footer-container input {
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 15px 20px;
  border-radius: 8px;
  color: #343434;
  font-size: 16px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  max-width: 460px;
  outline: 0;
  box-shadow: none;
}

.footer-img {
  width: auto;
  height: 70px;
}

.footer-socials {
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.footer-socials div {
  height: 40px;
  width: 40px;
  background-color: #fff;
}

.footer-bottom-container {
  align-items: center;
  justify-content: space-between;
  opacity: 0.6;
}

.footer-container .main-btn {
  text-transform: none;
}
.footer-endereco h5,
.footer-menu h5{
  font-size: 18px !important;
  font-weight: bold !important;
}

.footer-menu .menu-btn-scroll {
  width: fit-content !important;
}

@media (max-width: 450px) {
  .footer-content div {
    min-width: 210px;
  }
}

@media (max-width: 1000px) {
  .footer-wrap {
    flex-direction: column;
    gap: 40px;
  }

  .footer-bottom-container {
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 60px;
  }

  .newsletter-container {
    margin-top: 20px;
  }

  .footer-menu {
    margin-bottom: 0 !important;
  }
}


@media (max-width: 630px) {
  .footer-container {
    padding: 60px 20px;
  }
}

