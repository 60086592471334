@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


/* Apply the animation to the element */
#slide-in {
  opacity: 0; /* Ensure the element is hidden initially */
  animation: slideInFromLeft 1s ease-out 0.3s forwards;
}

#slide-in-right{
  opacity: 0; /* Ensure the element is hidden initially */
  animation: slideInFromRight 1s ease-out .6s forwards;
}


.hero-wrapper {
  background-image: url('../../assets/imgs/fundofoda.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 60% 60%;
  position: relative;
  background-color: #03287B;

}
.tercioimg{
  margin-left: 160px !important;
  max-width: 500px;
}

.hero-title{
  margin-left: 40px;
  max-width: 00px ;
}
.hero-container {
  padding: 100px 60px;
  padding-bottom: 10px;
  max-width: 1250px;
 display: flex;
  align-items: center !important;
  justify-content: center !important;

}


.hero-container p {
  color: #f1f1f1;
  font-size: 22px;
  line-height: 1.4;
  max-width: 600px;
}

.hero-container h1 {
  font-size: 3em;
  line-height: 1.3;
  letter-spacing: -2px;
  letter-spacing: 0.05em;
  font-weight: bolder;
  color: #fff;
}
.tercioimg{
  margin-top: 50px;
  max-width:500px;
}

.hero-container h2{
  color: #fff;
  font-family: 'futura-pt-light', sans-serif;
  font-weight: 400;
  font-size: 28px;

}
.hero-title{
  margin-left: 60px;
  max-width: 500px !important;
  margin-bottom: 250px !important;
}
.hero-container .disclaimer {
  color: #f2f2f2ee;
}

.hero-container h1 span {
  color: #48baef;
}

@media (max-width: 768px) {
  .hero-content p{
    font-size: 18px;
  }
}
/* Responsividade */
@media (max-width: 1024px) {
  .hero-container {
    padding: 80px 40px;
  }

  .tercioimg {
    margin-top: 40px !important;
    margin-left: 0 !important;
    max-width: 100%;
  }

  .hero-title {
    margin-left: 0 !important;
    max-width: 100%;
  }

  .hero-container p {
    font-size: 20px;
  }

  .hero-container h1 {
    font-size: 2.5em;
  }

  .hero-container h2 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .hero-container {
    padding: 100px 20px;
  }

  .hero-content p {
    font-size: 18px;
  }

  .hero-container h1 {
    font-size: 2em;
  }

  .hero-container h2 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .hero-container {
    padding: 40px 10px;
  }

  .hero-container p {
    font-size: 16px;
  }

  .hero-container h1 {
    font-size: 1.8em;
  }

  .hero-container h2 {
    font-size: 18px;
  }
}