
.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 101;
  width: 100%;
  padding: 20px 0;

  transition: 0.3s;
}
.header-wrapper.scrolled {
  position: fixed;
  background-color: #021f60;
  box-shadow: none;
  border-bottom: none;
  transition: 0.5s;
}
.btn-menu {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  transition: color 0.3s;
}

.btn-menu::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px; /* Posição da linha de underline */
  width: 0;
  height: 1px; /* Espessura da linha de underline */
  background-color: rgb(230, 250, 49); /* Cor da linha de underline */
  transition: width 0.3s; /* Transição da largura da linha de underline */
}

.btn-menu:hover::after {
  width: 100%; /* Largura total da linha de underline */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
}

.dropdown-menu li a:hover {
  background-color: #f1f1f1;
}


header {
  padding: 0 55px !important;
  display: flex;
  align-items: center;
  z-index: 100;
  margin: auto;
  height: 100%;
  gap: 20px;
  justify-content: space-between;
  max-width: 1250px;
}

.nav-container,
nav ul {
  display: flex;
  gap: 25px;
  list-style: none;
}

nav ul li button {
  background: none;
  font: inherit;
  cursor: pointer;
  color: #fff !important;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-links i {
  color: #fff !important;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

/* Estilos para telas menores (mobile) */
@media (max-width: 768px) {
  .nav-container {
    display: none;
  }

  .menu-toggle {
    display: block;
  }
}

