.mapa2{
    line-height: 0;
}

.mapa2-container{
    line-height: 0;
 
}


@media (max-width: 768px) {
    .mapa2{
        height: 200px;
    }

}