.tampinha-wrapper {
    padding: 40px 20px;
}

.tampinha-container {
    max-width: 1170px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
img.img-tampinha2{
    max-width: 450px !important;
}
.tampinha-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.tampinha-container h1 {
    font-size: 50px;
    letter-spacing: 0.05rem;
    color: #03287B;
    line-height: 1.3;
    font-family: 'Primaria2';
  }

.img-tampinha{
    max-width: 300px !important;
}
.tampinha-content p {
    font-size: 18px;
    text-align: left !important;
    color: #031e5d;
    line-height: 1.3;
    font-family: 'Secundaria';
}
.texto{
    display: flex !important;
    flex-direction: column;
    align-items: left !important;
    justify-content: center !important;
    max-width: 600px !important;
    margin: auto;
    text-align: left;
    gap: 20px;
}

.tampinha-titles{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.tampinha-titles img{
    max-width: 60%;
}
.titulo3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 50px;
}
.voluntariar h3{
    font-size: 50px;
    font-family: 'Primaria2';
    color: #03287B;
}
.voluntariar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.entrectt{
    background-color: #02226c;
    color: white;
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 18px;

}
.textos-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.botoes-tampinha{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.slider-container {
    width: 95%;
    margin: 50px auto;
  }
  
  .slider-item {
    padding: 10px;
  }
  
  .slick-slide img {
    width: 100%;
    height: auto;
  }
.baixar{
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #FFBB40;
    max-width: fit-content;
    margin: auto !important;
    color: #02226c !important;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Secundaria';
}
.custom-arrow {
    color: #001954;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
  }
  
  .custom-next-arrow {
    right: -20px;
  }
  
  .custom-prev-arrow {
    left: -20px;
  }
  
  /* Optionally style the arrows */
  .custom-arrow::before {
    font-size: 30px;
    color: #001954;
  }
  @media (max-width: 1024px) {
    .tampinha-container {
        gap: 20px;
    }

    .tampinha-container h1 {
        font-size: 40px;
    }

    .voluntariar h3 {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .tampinha-container {
        flex-direction: column;
        padding-top: 10px;
    }

    .tampinha-container h1 {
        font-size: 35px;
        text-align: center;
    }

    .voluntariar h3 {
        font-size: 40px;
    }

    .botoes-tampinha {
        gap: 20px;
        flex-direction: column;
    }

    .textos-flex {
        gap: 20px;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .tampinha-container h1 {
        font-size: 40px;
    }

    .voluntariar h3 {
        font-size: 40px;
    }

    .tampinha-content p, .entrectt, .baixar {
        font-size: 16px;
    }

    .botoes-tampinha, .textos-flex {
        gap: 10px;
    }
}