@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in {
    opacity: 0; /* Ensure the element is hidden initially */
}

.slide-in-visible {
    animation: slideInFromLeft 1s ease-out 0.3s forwards;
}

  
.inclusao-wrapper {
    padding: 100px 50px;
}

.inclusao-container {
    max-width: 1170px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.inclusao-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.box-txt{
    max-width: 960px;
    text-align: left;
    font-family: 'Secundaria';
}

.inclusao-container div {
    flex: 1;
}
.icones{
    max-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.inclusao-content h2 {
    font-size: 60px;
    letter-spacing: 0.05rem;
    color: #03287B;
    line-height: 1.3;
      font-family: 'Primaria2';
}

.inclusao-content p {
    font-size: 20px;
    line-height: 1.3;
    color: #02226c;
    font-family: 'Secundaria';
}

.inclusao-img {
    display: flex !important;
    gap: 50px !important;
    align-items: center !important;
    justify-content: center !important;;
}
.shape-small-4{
    z-index: 100;
    position: absolute;
    border: 50%;
    background-color: #081b47;
    filter: blur(50px);
  }
  .shape-small-4 {
    width: 150px;
    height: 150px;
    bottom: 50rem;
    top: 60rem;
    left: 75rem;
  }

@media (max-width: 768px) {
    .inclusao-wrapper{
        padding: 60px 20px;
    }
    .inclusao-container {
        padding-top: 10px;
        flex-direction: column;
        align-items: center ;
        justify-content: center;
        margin-left: 50px;
    }
}
