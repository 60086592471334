.noticia-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    background-color: #f9f9f9;
    display: flex;
  }
  .noticia-image{
    max-width: 300px;
    max-height: 300px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
 
  }
  
  .noticia-title {
    font-size: 24px;
    color: #011952 ;
    margin-bottom: 8px;
  }
  
  .noticia-date {

    font-size: 14px;
    margin-bottom: 16px;
    color: #03287B ;
  }
  
  .noticia-description {
    font-size: 18px;
    margin-bottom: 16px;
    
  }
  
  .noticia-author {
    font-style: italic;
    font-weight: bold;
    color: #484848 ;
  }
  